import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Clock, Calendar, Building2, Users } from 'lucide-react';
import { format, parse } from 'date-fns';
import { useAuctions } from '../hooks/useAuctions';
import { BidModal } from '../components/modals/BidModal';
import { AuthPromptModal } from '../components/modals/AuthPromptModal';
import { FilterBar } from '../components/FilterBar';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { Countdown } from '../components/Countdown';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';

export const Home: React.FC = () => {
  const [selectedAuction, setSelectedAuction] = useState<any>(null);
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const { auctions, loading } = useAuctions();
  const { t } = useLanguage();
  const { user } = useAuth();
  const [filters, setFilters] = useState({
    location: '',
    sport: 'Tennis',
    date: '',
    time: '',
  });

  const handleFilterChange = (newFilters: {
    location: string;
    sport: string;
    date: string;
    time: string;
  }) => {
    setFilters(newFilters);
  };

  const handleBidClick = (auction: any) => {
    if (!user) {
      setShowAuthPrompt(true);
    } else {
      setSelectedAuction(auction);
    }
  };

  const formatAuctionTime = (auction: any) => {
    try {
      // Check if startTime is a Firebase Timestamp
      if (auction.startTime?.toDate) {
        return format(auction.startTime.toDate(), 'h:mm a');
      }
      
      // If it's a string (HH:mm format)
      if (typeof auction.startTime === 'string') {
        // Create a date object for today with the given time
        const [hours, minutes] = auction.startTime.split(':');
        const date = new Date();
        date.setHours(parseInt(hours, 10));
        date.setMinutes(parseInt(minutes, 10));
        return format(date, 'h:mm a');
      }

      // If it's already a Date object
      if (auction.startTime instanceof Date) {
        return format(auction.startTime, 'h:mm a');
      }

      return 'Invalid time';
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Invalid time';
    }
  };

  // Adapted AuctionCard from VendorDashboard with Home's bid button logic
  const AuctionCard: React.FC<{ auction: any }> = ({ auction }) => {
    try {
      const eventStartTime = parse(
        `${auction.date} ${auction.startTime}`,
        'yyyy-MM-dd HH:mm',
        new Date()
      );

      return (
        <div className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
          {/* Profile Image Section */}
          <div className="w-full h-48 relative">
            <Link to={`/auction/${auction.id}`}>
              {auction?.court?.venue?.profileImage ? (
                <img
                  src={auction.court.venue.profileImage}
                  alt={auction.court.venue.name || 'Venue'}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gray-100 dark:bg-dark-700 flex items-center justify-center">
                  <Building2 className="h-12 w-12 text-gray-400" />
                </div>
              )}
            </Link>
            {/* Badges */}
            <div className="absolute top-3 right-3 flex flex-col gap-2">
              <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-indigo-100 text-indigo-800 dark:bg-indigo-900/50 dark:text-indigo-300">
                {auction.court?.type}
              </span>
              <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-white text-gray-700 shadow-sm">
                {auction.court?.type2}
              </span>
            </div>
          </div>

          {/* Auction Info */}
          <div className="p-4">
            {/* Court and Venue Names */}
            <div className="mb-3">
              <Link 
                to={`/auction/${auction.id}`}
                className="text-lg font-medium text-gray-900 dark:text-white hover:text-indigo-600 transition-colors"
              >
                {auction.court?.name || 'Unnamed Court'}
              </Link>
              <Link 
                to={`/center/${auction.court?.venue?.id}`}
                className="block mt-1 text-sm text-gray-600 dark:text-gray-400 hover:text-indigo-600 transition-colors"
              >
                {auction.court?.venue?.name || 'Unknown Venue'}
              </Link>
            </div>

            {/* Bid and Participants Info */}
            <div className="flex justify-between items-start mb-3">
              <div>
                <p className="text-xs text-gray-600 dark:text-gray-400 mb-1">Current Bid</p>
                <p className="text-xl font-bold text-indigo-600">
                  €{auction.currentBid || auction.basePrice}
                </p>
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 text-gray-500 dark:text-gray-400 mr-1" />
                <span className="text-sm text-gray-600 dark:text-gray-400">
                  {auction.participants || 0}
                </span>
              </div>
            </div>

            {/* Date, Time and Countdown */}
            <div className="space-y-1">
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                <Calendar className="h-4 w-4 mr-1" />
                {format(eventStartTime, 'MMMM d, yyyy')}
              </div>
              <div className="flex items-center text-sm text-gray-600 dark:text-gray-400">
                <Clock className="h-4 w-4 mr-1" />
                {format(parse(auction.startTime, 'HH:mm', new Date()), 'HH:mm')} - {' '}
                {format(parse(auction.endTime, 'HH:mm', new Date()), 'HH:mm')}
              </div>
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-1 text-orange-500" />
                <Countdown endTime={new Date(auction.auctionEndTime)} />
              </div>
            </div>
            
            {/* Bid Button */}
            <button
              onClick={() => handleBidClick(auction)}
              className="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors"
            >
              {t('bid.placeBid')}
            </button>
          </div>
        </div>
      );
    } catch (error) {
      console.error('Error rendering auction card:', error, auction);
      return null;
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="w-full max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
      <div className="sm:mb-8">
        <div className="sm:hidden text-center mb-6 mt-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
            {t('home.welcome')}
          </h1>
          <p className="mt-2 text-base text-gray-600 dark:text-gray-300">
            {t('home.subtitle')}
          </p>
        </div>

        <div className="hidden sm:block mb-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
            {t('home.welcome')}
          </h1>
          <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">
            {t('home.subtitle')}
          </p>
        </div>
      </div>

      <div className="relative z-20">
        <div className="sm:hidden scale-90 origin-top -mt-2 sticky top-16 bg-gray-50 dark:bg-dark-900 pb-2">
          <FilterBar onFilterChange={handleFilterChange} />
        </div>
        <div className="hidden sm:block">
          <FilterBar onFilterChange={handleFilterChange} />
        </div>
      </div>

      {/* Mobile view */}
      <div className="sm:hidden mt-3 relative z-10">
        <div className="overflow-x-auto">
          <div className="flex gap-3 pb-4 snap-x snap-mandatory px-4 min-w-full hide-scrollbar">
            {auctions?.map((auction) => (
              <div key={auction.id} className="flex-none w-[260px] snap-start">
                <AuctionCard auction={auction} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Desktop view */}
      <div className="hidden sm:grid mt-8 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
        {auctions?.map((auction) => (
          <AuctionCard key={auction.id} auction={auction} />
        ))}
      </div>

      {(!auctions || auctions.length === 0) && (
        <div className="text-center py-12">
          <p className="text-gray-500 dark:text-gray-400">{t('home.noAuctions')}</p>
        </div>
      )}

      {selectedAuction && (
        <BidModal
          auction={{
            id: selectedAuction.id,
            courtName: selectedAuction.court?.name,
            venueName: selectedAuction.court?.venue?.name,
            currentBid: selectedAuction.currentBid,
            image: selectedAuction.court?.venue?.profileImage
          }}
          onClose={() => setSelectedAuction(null)}
        />
      )}

      {showAuthPrompt && (
        <AuthPromptModal onClose={() => setShowAuthPrompt(false)} />
      )}
    </div>
  );
};