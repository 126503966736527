import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, orderBy, Timestamp, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface LiveAuction {
  id: string;
  courtId: string;
  courtName: string;
  venueName: string;
  currentBid: number;
  startTime: Timestamp;
  endTime: Timestamp;
  participants: number;
  totalBids: number;
  status: 'active' | 'completed' | 'cancelled';
}

export const useAdminAuctions = () => {
  const [auctions, setAuctions] = useState<LiveAuction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const now = Timestamp.now();
    const auctionsRef = collection(db, 'auctions');
    const q = query(
      auctionsRef,
      where('status', '==', 'active'),
      where('endTime', '>', now),
      orderBy('endTime', 'asc')
    );

    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        try {
          const auctionsData = await Promise.all(
            snapshot.docs.map(async (auctionDoc) => {
              const auctionData = auctionDoc.data();
              
              // Get court details using doc reference
              const courtDoc = await getDoc(doc(db, 'courts', auctionData.courtId));
              const courtData = courtDoc.exists() ? courtDoc.data() : null;

              // Get venue details using doc reference
              let venueData = null;
              if (courtData?.venueId) {
                const venueDoc = await getDoc(doc(db, 'venues', courtData.venueId));
                venueData = venueDoc.exists() ? venueDoc.data() : null;
              }

              // Get bids information
              const bidsRef = collection(db, 'bids');
              const bidsQuery = query(bidsRef, where('auctionId', '==', auctionDoc.id));
              const bidsSnapshot = await getDocs(bidsQuery);

              const uniqueParticipants = new Set(bidsSnapshot.docs.map(bid => bid.data().userId));

              return {
                id: auctionDoc.id,
                ...auctionData,
                courtName: courtData?.name || 'Unknown Court',
                venueName: venueData?.name || 'Unknown Venue',
                participants: uniqueParticipants.size,
                totalBids: bidsSnapshot.size
              } as LiveAuction;
            })
          );

          setAuctions(auctionsData);
          setLoading(false);
        } catch (err) {
          console.error('Error processing auctions:', err);
          setError(err instanceof Error ? err.message : 'Failed to load auctions');
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error fetching auctions:', err);
        setError(err instanceof Error ? err.message : 'Failed to load auctions');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return { auctions, loading, error };
};