import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { SignUp } from './pages/SignUp';
import { EmailVerification } from './pages/EmailVerification';
import { VerifyEmail } from './pages/VerifyEmail';
import { VendorDashboardWrapper } from './components/vendor/VendorDashboardWrapper';
import { VendorProfile } from './pages/vendor/VendorProfile';
import { UserDashboard } from './pages/UserDashboard';
import { MyBids } from './pages/MyBids';
import { AuctionDetail } from './pages/AuctionDetail';
import { SportsCenter } from './pages/SportsCenter';
import { AdminDashboard } from './pages/admin/AdminDashboard';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import { DarkModeProvider } from './context/DarkModeContext';
import { NotificationProvider } from './context/NotificationContext';
import { LanguageProvider } from './context/LanguageContext';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ForgotPassword } from './pages/ForgotPassword';

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <DarkModeProvider>
          <NotificationProvider>
            <LanguageProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="/my-bids" element={
                      <ProtectedRoute>
                        <MyBids />
                      </ProtectedRoute>
                    } />
                    <Route path="/auction/:id" element={<AuctionDetail />} />
                    <Route path="/center/:id" element={<SportsCenter />} />
                    <Route path="/dashboard" element={
                      <ProtectedRoute>
                        <UserDashboard />
                      </ProtectedRoute>
                    } />
                  </Route>

                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/email-verification" element={<EmailVerification />} />
                  <Route path="/verify-email" element={<VerifyEmail />} />
                  
                  {/* Admin Routes */}
                  <Route path="/admin/*" element={
                    <ProtectedRoute roles={['admin']}>
                      <AdminDashboard />
                    </ProtectedRoute>
                  } />

                  {/* Vendor Routes */}
                  <Route path="vendor">
                    <Route
                      path="dashboard"
                      element={
                        <ProtectedRoute roles={['vendor']}>
                          <VendorDashboardWrapper />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="profile"
                      element={
                        <ProtectedRoute roles={['vendor']}>
                          <VendorProfile />
                        </ProtectedRoute>
                      }
                    />
                  </Route>

                  <Route path="/forgot-password" element={<ForgotPassword />} />

                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </BrowserRouter>
            </LanguageProvider>
          </NotificationProvider>
        </DarkModeProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;