import React, { useState } from 'react';
import { X, Building2, CreditCard } from 'lucide-react';

interface AddPayoutAccountModalProps {
  onClose: () => void;
}

export const AddPayoutAccountModal: React.FC<AddPayoutAccountModalProps> = ({ onClose }) => {
  const [formData, setFormData] = useState({
    accountHolderName: '',
    accountNumber: '',
    routingNumber: '',
    bankName: '',
    accountType: 'checking',
    taxId: ''
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle payout account addition here
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-6 border-b dark:border-dark-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Add Payout Account</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Account Holder Name *</label>
            <input
              type="text"
              required
              value={formData.accountHolderName}
              onChange={e => setFormData(prev => ({ ...prev, accountHolderName: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 dark:border-dark-600 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Bank Name *</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Building2 className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                required
                value={formData.bankName}
                onChange={e => setFormData(prev => ({ ...prev, bankName: e.target.value }))}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Account Number *</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <CreditCard className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                required
                value={formData.accountNumber}
                onChange={e => setFormData(prev => ({ ...prev, accountNumber: e.target.value }))}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Routing Number *</label>
            <input
              type="text"
              required
              value={formData.routingNumber}
              onChange={e => setFormData(prev => ({ ...prev, routingNumber: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 dark:border-dark-600 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Account Type *</label>
            <select
              required
              value={formData.accountType}
              onChange={e => setFormData(prev => ({ ...prev, accountType: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 dark:border-dark-600 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            >
              <option value="checking">Checking</option>
              <option value="savings">Savings</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Tax ID (EIN/SSN) *</label>
            <input
              type="text"
              required
              value={formData.taxId}
              onChange={e => setFormData(prev => ({ ...prev, taxId: e.target.value }))}
              className="mt-1 block w-full border border-gray-300 dark:border-dark-600 rounded-md px-3 py-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white dark:bg-dark-900 text-gray-900 dark:text-white"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Add Payout Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};