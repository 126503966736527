import React from 'react';
import { X, Clock, Calendar, Users, DollarSign } from 'lucide-react';
import { format } from 'date-fns';
import { Countdown } from '../../Countdown';
import { useLanguage } from '../../../context/LanguageContext';
import { Auction } from '../../../types';
import { parse } from 'date-fns';

interface LiveAuctionDetailsModalProps {
  auction: Auction;
  onClose: () => void;
}

export const LiveAuctionDetailsModal: React.FC<LiveAuctionDetailsModalProps> = ({ auction, onClose }) => {
  const { t } = useLanguage();
  const eventStartTime = parse(
    `${auction.date} ${auction.startTime}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  );
  const eventEndTime = parse(
    `${auction.date} ${auction.endTime}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-xl w-full max-w-lg">
        <div className="flex justify-between items-center p-6 border-b dark:border-dark-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            {t('vendor.auctionDetails')}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="p-6">
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                {auction.court.name}
              </h3>
              <div className="flex gap-2">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 dark:bg-indigo-900/50 text-indigo-800 dark:text-indigo-300">
                  {auction.court?.type}
                </span>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-white text-gray-700">
                  {auction.court?.type2}
                </span>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {auction.court.venue?.name}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-6">
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">{t('bid.currentBid')}</p>
                <p className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400">
                  ${auction.currentBid}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">{t('vendor.totalBids')}</p>
                <div className="flex items-center mt-1">
                  <Users className="h-5 w-5 text-gray-400 mr-2" />
                  <p className="text-lg font-medium text-gray-900 dark:text-white">
                    {auction.participants} {t('vendor.participants')}
                  </p>
                </div>
              </div>
            </div>

            <div className="space-y-3">
              <div className="flex items-center">
                <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                <span className="text-gray-900 dark:text-white">
                  {format(eventStartTime, 'MMMM d, yyyy')}
                </span>
              </div>
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-gray-400 mr-2" />
                <span className="text-gray-900 dark:text-white">
                  {format(eventStartTime, 'h:mm a')} - {format(eventEndTime, 'h:mm a')}
                </span>
              </div>
              <div className="flex items-center">
                <Clock className="h-5 w-5 text-gray-400 mr-2" />
                <Countdown endTime={new Date(auction.auctionEndTime)} />
              </div>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                {t('vendor.bidHistory')}
              </h4>
              <div className="bg-gray-50 dark:bg-dark-900 rounded-lg p-4">
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {t('vendor.startingPrice')}
                    </span>
                    <span className="text-sm font-medium text-gray-900 dark:text-white">
                      ${auction.basePrice || 0}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {t('vendor.currentHighest')}
                    </span>
                    <span className="text-sm font-medium text-indigo-600 dark:text-indigo-400">
                      ${auction.currentBid || 0}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {t('vendor.totalIncrease')}
                    </span>
                    <span className="text-sm font-medium text-green-600 dark:text-green-400">
                      ${(auction.currentBid || 0) - (auction.basePrice || 0)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};