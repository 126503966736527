import { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

export const useStripeConnect = () => {
  const { user } = useAuth();
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkStripeStatus = async () => {
      console.log('useStripeConnect - Starting check');
      console.log('useStripeConnect - User:', user);
      
      if (!user?.id) {
        console.log('useStripeConnect - No user ID');
        setIsLoading(false);
        return;
      }

      try {
        console.log('useStripeConnect - Checking status for user:', user.id);
        const stripeDoc = await getDoc(doc(db, 'stripeConnect', user.id));
        console.log('useStripeConnect - Doc exists:', stripeDoc.exists());
        console.log('useStripeConnect - Doc data:', stripeDoc.data());
        
        const isConnected = stripeDoc.exists() && stripeDoc.data()?.status === 'active';
        console.log('useStripeConnect - Setting isConnected to:', isConnected);
        
        setIsConnected(isConnected);
      } catch (error) {
        console.error('useStripeConnect - Error:', error);
      }
      setIsLoading(false);
    };

    checkStripeStatus();
  }, [user]);

  return { isConnected, isLoading };
};