import React from 'react';
import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';

export const Footer: React.FC = () => {
  const { t } = useLanguage();
  
  return (
    <footer className="bg-white dark:bg-dark-800 border-t border-gray-200 dark:border-dark-700">
      <div className="w-full max-w-[2000px] mx-auto px-4 sm:px-6 lg:px-8">
        {/* Desktop Footer */}
        <div className="hidden sm:grid grid-cols-3 gap-4 py-6">
          {/* Brand */}
          <div className="space-y-2 text-center sm:text-left">
            <div className="flex justify-center sm:justify-start">
              <span className="text-xl sm:text-2xl font-black tracking-wider text-gray-900 dark:text-white rounded-lg">
                BIDCOURT
              </span>
            </div>
            <p className="text-gray-500 dark:text-gray-400 text-sm max-w-[260px] mx-auto sm:mx-0">
              {t('home.subtitle').replace(' e ', '\ne ')}
            </p>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 uppercase tracking-wider">
              {t('auction.contact')}
            </h3>
            <div className="mt-2">
              <div className="flex items-center text-gray-500 dark:text-gray-400 text-sm">
                <Mail className="h-4 w-4 mr-2" />
                <a href="mailto:info@bidcourt.it" className="hover:text-indigo-600 dark:hover:text-indigo-400">
                  info@bidcourt.it
                </a>
              </div>
            </div>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 uppercase tracking-wider">
              {t('nav.legal')}
            </h3>
            <ul className="mt-2 space-y-2">
              <li>
                <Link to="/privacy" className="text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 text-sm">
                  {t('footer.privacy')}
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 text-sm">
                  {t('footer.terms')}
                </Link>
              </li>
              <li>
                <Link to="/cookies" className="text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 text-sm">
                  {t('footer.cookies')}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Mobile Footer */}
        <div className="sm:hidden py-3">
          <div className="flex flex-col items-center space-y-2">
            {/* Logo */}
            <span className="text-lg font-black tracking-wider text-gray-900 dark:text-white">
              BIDCOURT
            </span>
            
            {/* Contact and Legal Links */}
            <div className="flex flex-col items-center space-y-2">
              <a 
                href="mailto:info@bidcourt.it" 
                className="text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600"
              >
                info@bidcourt.it
              </a>
              
              <div className="flex items-center space-x-2 text-sm">
                <Link 
                  to="/privacy" 
                  className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
                >
                  Privacy
                </Link>
                <span className="text-gray-300">•</span>
                <Link 
                  to="/terms" 
                  className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
                >
                  Terms
                </Link>
                <span className="text-gray-300">•</span>
                <Link 
                  to="/cookies" 
                  className="text-gray-500 dark:text-gray-400 hover:text-indigo-600"
                >
                  Cookies
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Copyright - smaller padding on mobile */}
        <div className="py-1 sm:py-2 border-t border-gray-200 dark:border-dark-700">
          <p className="text-xs text-gray-400 dark:text-gray-500 text-center">
            © {new Date().getFullYear()} BidCourt
          </p>
        </div>
      </div>
    </footer>
  );
};