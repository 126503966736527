import React, { useState } from 'react';
import { CreditCard, Plus, AlertTriangle, Building2 } from 'lucide-react';
import { AddPaymentMethodModal } from './AddPaymentMethodModal';
import { AddPayoutAccountModal } from './AddPayoutAccountModal';
import { ConfirmationModal } from './ConfirmationModal';
import { useLocation } from 'react-router-dom';

export const ProfileBilling: React.FC = () => {
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showAddPayoutModal, setShowAddPayoutModal] = useState(false);
  const [showRemoveCardModal, setShowRemoveCardModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const location = useLocation();
  const isVendor = location.pathname.startsWith('/vendor');

  const hasActiveBids = true;
  const cards = [
    {
      id: '1',
      last4: '4242',
      expiryDate: '12/24',
      isDefault: true,
    }
  ];

  const payouts = [
    {
      id: 1,
      courtName: 'Center Court',
      date: 'March 15, 2024',
      amount: 45.00,
      status: 'Completed'
    },
    {
      id: 2,
      courtName: 'Professional Court 1',
      date: 'March 14, 2024',
      amount: 38.00,
      status: 'Pending'
    }
  ];

  const handleRemoveCard = (cardId: string) => {
    if (hasActiveBids) return;
    setSelectedCardId(cardId);
    setShowRemoveCardModal(true);
  };

  const confirmRemoveCard = () => {
    // Handle card removal logic here
    setShowRemoveCardModal(false);
    setSelectedCardId(null);
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900 dark:text-white">
          {isVendor ? 'Payout Settings' : 'Billing & Payments'}
        </h2>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {isVendor 
            ? 'Manage your payout methods and view transaction history.'
            : 'Manage your payment methods and billing history.'}
        </p>
      </div>

      {isVendor ? (
        // Vendor Payout Section
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <h3 className="text-sm font-medium text-gray-900 dark:text-white">Payout Account</h3>
            <button
              onClick={() => setShowAddPayoutModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Payout Account
            </button>
          </div>

          <div className="bg-white dark:bg-dark-800 border dark:border-dark-600 rounded-lg p-4">
            <div className="flex items-center space-x-4">
              <Building2 className="h-6 w-6 text-gray-400 dark:text-gray-500" />
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">No payout account added</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">Add a bank account to receive payouts</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // User Payment Methods Section
        <div className="space-y-4">
          <h3 className="text-sm font-medium text-gray-900 dark:text-white">Payment Methods</h3>
          
          {hasActiveBids && (
            <div className="flex items-start p-4 bg-yellow-50 dark:bg-yellow-900/30 rounded-lg">
              <AlertTriangle className="h-5 w-5 text-yellow-400 dark:text-yellow-300 mt-0.5 flex-shrink-0" />
              <p className="ml-3 text-sm text-yellow-700 dark:text-yellow-200">
                Payment methods cannot be removed while you have active bids.
              </p>
            </div>
          )}

          <div className="grid gap-4">
            {cards.map((card) => (
              <div key={card.id} className="flex flex-col sm:flex-row sm:items-center justify-between p-4 border dark:border-dark-600 rounded-lg space-y-2 sm:space-y-0 bg-white dark:bg-dark-800">
                <div className="flex items-center space-x-4">
                  <CreditCard className="h-6 w-6 text-gray-400 dark:text-gray-500 flex-shrink-0" />
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">•••• •••• •••• {card.last4}</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Expires {card.expiryDate}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-2 justify-between sm:justify-end">
                  {card.isDefault && (
                    <span className="px-2.5 py-0.5 text-xs font-medium bg-green-100 dark:bg-green-900/50 text-green-800 dark:text-green-400 rounded-full">
                      Default
                    </span>
                  )}
                  <button 
                    className={`text-sm ${hasActiveBids ? 'text-gray-300 dark:text-gray-600 cursor-not-allowed' : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'}`}
                    onClick={() => !hasActiveBids && handleRemoveCard(card.id)}
                    disabled={hasActiveBids}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}

            <button 
              onClick={() => setShowAddPaymentModal(true)}
              className="flex items-center justify-center p-4 border border-dashed dark:border-dark-600 rounded-lg text-sm text-gray-600 dark:text-gray-400 hover:border-gray-400 dark:hover:border-gray-500 hover:text-gray-700 dark:hover:text-gray-300 bg-white dark:bg-dark-800"
            >
              <Plus className="h-5 w-5 mr-2" />
              Add new payment method
            </button>
          </div>
        </div>
      )}

      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-900 dark:text-white">
          {isVendor ? 'Payout History' : 'Billing History'}
        </h3>
        
        <div className="bg-white dark:bg-dark-800 border dark:border-dark-600 rounded-lg divide-y divide-gray-200 dark:divide-dark-700">
          {payouts.map((payout) => (
            <div key={payout.id} className="flex flex-col sm:flex-row sm:items-center justify-between p-4 space-y-2 sm:space-y-0">
              <div>
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {payout.courtName}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">{payout.date}</p>
              </div>
              <div className="flex items-center justify-between sm:flex-col sm:items-end">
                <p className="text-sm font-medium text-gray-900 dark:text-white">${payout.amount.toFixed(2)}</p>
                <span className={`px-2.5 py-0.5 text-xs font-medium rounded-full ${
                  payout.status === 'Completed' 
                    ? 'bg-green-100 dark:bg-green-900/50 text-green-800 dark:text-green-400'
                    : 'bg-yellow-100 dark:bg-yellow-900/50 text-yellow-800 dark:text-yellow-400'
                }`}>
                  {payout.status}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showAddPaymentModal && (
        <AddPaymentMethodModal onClose={() => setShowAddPaymentModal(false)} />
      )}

      {showAddPayoutModal && (
        <AddPayoutAccountModal onClose={() => setShowAddPayoutModal(false)} />
      )}

      {showRemoveCardModal && (
        <ConfirmationModal
          title="Remove Payment Method"
          message="Are you sure you want to remove this payment method? This action cannot be undone."
          confirmText="Remove"
          onConfirm={confirmRemoveCard}
          onCancel={() => setShowRemoveCardModal(false)}
        />
      )}
    </div>
  );
};