import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../context/AuthContext';
import type { Auction } from '../types';

export const useVendorAuctions = () => {
  const [auctions, setAuctions] = useState<Auction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user?.id) {
      setLoading(false);
      return;
    }

    setLoading(true);
    
    const auctionsQuery = query(
      collection(db, 'auctions'),
      where('venueId', '==', user.id),
      where('status', '==', 'active'),
      orderBy('date', 'asc'),
      orderBy('startTime', 'asc')
    );

    const unsubscribe = onSnapshot(
      auctionsQuery,
      async (snapshot) => {
        try {
          // First, get the venue data to have the profile image
          const venueDoc = await getDoc(doc(db, 'venues', user.id));
          const venueData = venueDoc.data();
          
          const auctionsData = await Promise.all(
            snapshot.docs.map(async (auctionDoc) => {
              const data = auctionDoc.data();
              
              // Fetch court data for each auction
              const courtDoc = await getDoc(doc(db, 'courts', data.courtId));
              const courtData = courtDoc.data();

              return {
                id: auctionDoc.id,
                courtId: data.courtId,
                date: data.date,
                startTime: data.startTime,
                endTime: data.endTime,
                basePrice: data.basePrice,
                currentBid: data.currentBid,
                participants: data.participants || 0,
                status: data.status,
                createdAt: data.createdAt,
                court: {
                  id: data.courtId,
                  name: courtData?.name || 'Unnamed Court',
                  type: courtData?.type,
                  type2: courtData?.type2,
                  image: courtData?.image,
                  venue: {
                    id: user.id,
                    name: venueData?.name || user.firstName,
                    profileImage: venueData?.profileImage || null,
                  },
                },
                auctionEndTime: data.auctionEndTime,
              } as Auction;
            })
          );

          setAuctions(auctionsData);
          setLoading(false);
          setError(null);
        } catch (err) {
          console.error('Error processing auctions:', err);
          setError('Failed to load auctions');
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error in auctions subscription:', err);
        setError('Failed to subscribe to auctions');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [user?.id]);

  return { auctions, loading, error };
};
