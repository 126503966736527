// src/components/admin/LiveAuctions.tsx
import React, { useState } from 'react';
import { Search, Clock, Calendar, Users, DollarSign } from 'lucide-react';
import { format } from 'date-fns';
import { useAdminAuctions } from '../../hooks/useAdminAuctions';
import { LoadingSpinner } from '../LoadingSpinner';
import { LiveAuctionDetailsModal } from './modals/LiveAuctionDetailsModal';
import { Countdown } from '../Countdown'; // Add this import

export const LiveAuctions: React.FC = () => {
  const [search, setSearch] = useState('');
  const [selectedAuction, setSelectedAuction] = useState<any>(null);
  const { auctions, loading, error } = useAdminAuctions();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  const filteredAuctions = auctions.filter(auction => {
    if (!search) return true;
    
    const searchLower = search.toLowerCase();
    return (
      auction.courtName.toLowerCase().includes(searchLower) ||
      auction.venueName.toLowerCase().includes(searchLower) ||
      auction.court?.venue?.name?.toLowerCase().includes(searchLower)
    );
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Live Auctions</h2>
        
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search by court or venue name..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="pl-10 pr-4 py-2 border border-gray-300 dark:border-dark-600 rounded-md bg-white dark:bg-dark-900 text-gray-900 dark:text-white w-72"
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {filteredAuctions.map((auction) => (
          <div key={auction.id} className="bg-white dark:bg-dark-800 rounded-lg shadow-md overflow-hidden">
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">{auction.courtName}</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">{auction.venueName}</p>

              <div className="mt-4">
                <p className="text-sm text-gray-500 dark:text-gray-400">Current Bid</p>
                <p className="text-2xl font-semibold text-indigo-600 dark:text-indigo-400">
                  ${auction.currentBid}
                </p>
              </div>

              <div className="mt-4 space-y-2">
                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                  <Users className="h-4 w-4 mr-2" />
                  {auction.participants?.length || 0} participants ({auction.totalBids || 0} bids)
                </div>

                <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
                  <Calendar className="h-4 w-4 mr-2" />
                  {format(auction.startTime.toDate(), 'MMM d, yyyy')}
                </div>

                <div className="flex items-center text-orange-500 dark:text-orange-400">
                  <Clock className="h-4 w-4 mr-2" />
                  <Countdown endTime={auction.endTime.toDate()} />
                </div>
              </div>

              <button
                onClick={() => setSelectedAuction(auction)}
                className="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors"
              >
                View Details
              </button>
            </div>
          </div>
        ))}

        {filteredAuctions.length === 0 && (
          <div className="col-span-full text-center py-12">
            <p className="text-gray-500 dark:text-gray-400">
              No auctions found matching your search.
            </p>
          </div>
        )}
      </div>

      {selectedAuction && (
        <LiveAuctionDetailsModal
          auction={selectedAuction}
          onClose={() => setSelectedAuction(null)}
        />
      )}
    </div>
  );
};
