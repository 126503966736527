import React, { useState, useEffect } from 'react';
import { Plus, Trophy, Bell, UserCircle, Camera } from 'lucide-react';
import { AddCourtModal } from '../../components/modals/AddCourtModal';
import { AddAuctionModal } from '../../components/modals/AddAuctionModal';
import { StripeConnectWarningModal } from '../../components/modals/StripeConnectWarningModal';
import { LiveAuctionDetailsModal } from '../../components/admin/modals/LiveAuctionDetailsModal';
import { ConfirmationModal } from '../../components/modals/ConfirmationModal';
import { useVendorAuctions } from '../../hooks/useVendorAuctions';
import { useCourts } from '../../hooks/useCourts';
import { useStripeConnect } from '../../hooks/useStripeConnect';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { VendorCourtsList } from '../../components/vendor/VendorCourtsList';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useNotification } from '../../context/NotificationContext';
import { useLanguage } from '../../context/LanguageContext';
import { DarkModeToggle } from '../../components/DarkModeToggle';
import { LanguageSwitcher } from '../../components/LanguageSwitcher';
import { ProfileMenu } from '../../components/modals/ProfileMenu';
import { ChangePasswordModal } from '../../components/modals/ChangePasswordModal';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Auction } from '../../types';
import { format, parse } from 'date-fns';
import { Countdown } from '../../components/Countdown';
import { useVenueData } from '../../hooks/useVenueData';

export const VendorDashboard: React.FC = () => {
  const { user, logout } = useAuth(); // Added logout from useAuth
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { t } = useLanguage();
  const [showAddCourtModal, setShowAddCourtModal] = useState(false);
  const [showAddAuctionModal, setShowAddAuctionModal] = useState(false);
  const [showStripeWarning, setShowStripeWarning] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState<any>(null);
  const [auctionToDelete, setAuctionToDelete] = useState<any>(null);
  const { courts, loading: courtsLoading } = useCourts({ venueId: user?.id });
  const { auctions, loading: auctionsLoading, error } = useVendorAuctions();
  const { isConnected, isLoading: stripeLoading } = useStripeConnect();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { venueData } = useVenueData();

  console.log('VendorDashboard render - isConnected:', isConnected);

  useEffect(() => {
    const requirePasswordChange = localStorage.getItem('requirePasswordChange');
    if (requirePasswordChange === 'true') {
      setShowPasswordModal(true);
      localStorage.removeItem('requirePasswordChange');
    }
  }, []);

  const handleAddAuctionClick = () => {
    if (!isConnected) {
      setShowStripeWarning(true);
    } else {
      setShowAddAuctionModal(true);
    }
  };

  const handleSetupStripe = () => {
    setShowStripeWarning(false);
    navigate('/vendor/settings');
  };

  const handleLogout = async () => {
    try {
      await logout();
      setShowProfileMenu(false);
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      showNotification('Failed to logout', 'error');
    }
  };

  if (courtsLoading || auctionsLoading || stripeLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  // Update the AuctionCard component with proper typing and null checks
  const AuctionCard: React.FC<{ auction: Auction }> = ({ auction }) => {
    const eventStartTime = parse(
      `${auction.date} ${auction.startTime}`,
      'yyyy-MM-dd HH:mm',
      new Date()
    );

    return (
      <div className="bg-dark-800 rounded-lg overflow-hidden">
        {/* Profile Image Section */}
        <div className="w-full h-48 relative">
          {auction?.court?.venue?.profileImage ? (
            <img
              src={auction.court.venue.profileImage}
              alt={auction.court.venue.name || 'Venue'}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full bg-dark-700 flex items-center justify-center">
              <Camera className="h-12 w-12 text-gray-500" />
            </div>
          )}

          {/* Keep these type badges on the image */}
          <div className="absolute top-3 right-3 flex flex-col gap-2">
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-indigo-100 dark:bg-indigo-900/50 text-indigo-800 dark:text-indigo-300">
              {auction.court.type}
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-xs font-semibold bg-white text-gray-700">
              {auction.court.type2}
            </span>
          </div>
        </div>

        {/* Auction Info */}
        <div className="p-4">
          <div className="flex items-center gap-2 mb-3">
            <h3 className="text-lg font-medium text-white">
              {auction.court.name || 'Unnamed Court'}
            </h3>
          </div>
          <div className="mt-2 space-y-1">
            <p className="text-sm text-gray-400">
              {format(eventStartTime, 'MMMM d, yyyy')}
            </p>
            <p className="text-sm text-gray-400">
              {format(parse(auction.startTime, 'HH:mm', new Date()), 'h:mm a')} - 
              {format(parse(auction.endTime, 'HH:mm', new Date()), 'h:mm a')}
            </p>
            <p className="text-sm text-gray-400">
              Current Bid: ${auction.currentBid}
            </p>
            <p className="text-sm text-gray-400">
              {auction.participants} participants
            </p>
            <div className="text-sm text-gray-400">
              <Countdown endTime={new Date(auction.auctionEndTime)} />
            </div>
          </div>
          
          <button
            className="w-full mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700"
            onClick={() => setSelectedAuction(auction)}
          >
            {t('vendor.viewDetails')}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-dark-900">
      {/* Top Navigation Bar */}
      <nav className="bg-white dark:bg-dark-800 border-b border-gray-200 dark:border-dark-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-black tracking-wider text-gray-900 dark:text-white rounded-lg">
                BIDCOURT
              </span>
            </div>
            <div className="flex items-center space-x-4">
              <button className="p-2 rounded-full text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300">
                <Bell className="h-6 w-6" />
              </button>
              <DarkModeToggle />
              <LanguageSwitcher />
              <button
                onClick={() => setShowProfileMenu(true)}
                className="p-2 rounded-full text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
              >
                <UserCircle className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header with buttons */}
        <div className="md:flex md:items-center md:justify-between mb-8">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              {t('vendor.greeting', { 
                name: venueData?.name || user?.firstName || 'Default Venue' 
              })}
            </h2>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
              {t('vendor.subtitle')}
            </p>
          </div>
          <div className="mt-4 flex space-x-3 md:mt-0">
            <button
              onClick={handleAddAuctionClick}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4 mr-2" />
              {t('vendor.addAuction')}
            </button>

            <button
              onClick={() => setShowAddCourtModal(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4 mr-2" />
              {t('vendor.addCourt')}
            </button>
          </div>
        </div>

        {/* Active Auctions */}
        <div className="mb-8">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            {t('vendor.activeAuctions')}
          </h3>
          {auctions && auctions.length > 0 ? (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              {auctions.map((auction) => (
                <AuctionCard key={auction.id} auction={auction} />
              ))}
            </div>
          ) : (
            <div className="text-center py-12 bg-dark-800 rounded-lg">
              <p className="text-gray-400">
                {t('vendor.noActiveAuctions')}
              </p>
            </div>
          )}
        </div>

        {/* Courts List */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            {t('vendor.yourCourts')}
          </h3>
          <VendorCourtsList />
        </div>

        {/* Modals */}
        {showAddCourtModal && (
          <AddCourtModal
            onClose={() => setShowAddCourtModal(false)}
            onAdd={(court) => {
              setShowAddCourtModal(false);
              showNotification(t('vendor.courtAdded'), 'success');
            }}
          />
        )}

        {showAddAuctionModal && courts && (
          <AddAuctionModal
            courts={courts}
            onClose={() => setShowAddAuctionModal(false)}
            onAdd={(auction) => {
              setShowAddAuctionModal(false);
              showNotification(t('vendor.auctionAdded'), 'success');
            }}
          />
        )}

        {showStripeWarning && (
          <StripeConnectWarningModal
            onClose={() => setShowStripeWarning(false)}
            onSetupStripe={handleSetupStripe}
          />
        )}

        {selectedAuction && (
          <LiveAuctionDetailsModal
            auction={selectedAuction}
            onClose={() => setSelectedAuction(null)}
          />
        )}

        {auctionToDelete && (
          <ConfirmationModal
            title={t('vendor.deleteAuction')}
            message={t('vendor.deleteAuctionConfirm')}
            confirmText={t('common.delete')}
            onConfirm={() => {
              setAuctionToDelete(null);
              showNotification(t('vendor.auctionDeleted'), 'success');
            }}
            onCancel={() => setAuctionToDelete(null)}
          />
        )}

        {showProfileMenu && (
          <ProfileMenu
            onClose={() => setShowProfileMenu(false)}
            onLogout={handleLogout}
          />
        )}

        {showPasswordModal && (
          <ChangePasswordModal 
            onClose={() => setShowPasswordModal(false)}
            isFirstLogin={true}
            onSuccess={async () => {
              // Update the user document to mark password as changed
              if (user?.id) {
                await setDoc(doc(db, 'users', user.id), {
                  hasChangedInitialPassword: true
                }, { merge: true });
              }
              setShowPasswordModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};