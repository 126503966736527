import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface StripeConnectWarningModalProps {
  onClose: () => void;
  onSetupStripe?: () => void;
}

export const StripeConnectWarningModal: React.FC<StripeConnectWarningModalProps> = ({ onClose, onSetupStripe }) => {
  const { t } = useLanguage();
  const { user } = useAuth();
  const [isAlreadyConnected, setIsAlreadyConnected] = useState(false);
  
  useEffect(() => {
    const checkStripeConnection = async () => {
      if (user?.id) {
        const stripeDoc = await getDoc(doc(db, 'stripeConnect', user.id));
        if (stripeDoc.exists() && stripeDoc.data().status === 'active') {
          setIsAlreadyConnected(true);
          onClose(); // Automatically close the modal if already connected
        }
      }
    };
    
    checkStripeConnection();
  }, [user, onClose]);

  const handleStripeSetup = () => {
    if (isAlreadyConnected) {
      console.log('Stripe already connected');
      onClose();
      return;
    }

    const connectUrl = import.meta.env.VITE_STRIPE_CONNECT_URL;
    console.log('Connect URL:', connectUrl); // Debug log
    
    if (!connectUrl) {
      console.error('Stripe Connect URL not configured');
      return;
    }

    const urlWithState = `${connectUrl}&state=${user?.id}`;
    console.log('Redirecting to:', urlWithState); // Debug log
    window.location.href = urlWithState;
  };

  if (isAlreadyConnected) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-dark-800 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b border-dark-700">
          <div className="flex items-center space-x-2">
            <span className="text-yellow-500">⚠️</span>
            <h2 className="text-lg font-medium text-white">
              Stripe Connect Richiesto
            </h2>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-300">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          <p className="text-gray-300 mb-4">
            Prima di poter creare aste, devi collegare il tuo account Stripe per ricevere i pagamenti. 
            Questa è una configurazione una tantum che ti permette di ricevere pagamenti dalle tue aste.
          </p>

          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 border border-gray-600 rounded-md text-gray-300 hover:bg-gray-700"
            >
              Annulla
            </button>
            <button
              onClick={handleStripeSetup}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
            >
              Configura Stripe
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};