import React, { useState } from 'react';
import { useLanguage } from '../context/LanguageContext';
import { format } from 'date-fns';
import { Trophy, Calendar, Clock } from 'lucide-react';
import { Countdown } from '../components/Countdown';
import { useUserBids } from '../hooks/useUserBids';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { BidModal } from '../components/modals/BidModal';
import { Link } from 'react-router-dom';

export const MyBids: React.FC = () => {
  const { t } = useLanguage();
  const { bids, loading, error } = useUserBids();
  const [selectedBid, setSelectedBid] = useState<any>(null);

  const handleIncreaseBid = (bid: any) => {
    setSelectedBid(bid);
  };

  const formatDate = (date: any) => {
    try {
      if (!date || !date.toDate) return '';
      return format(date.toDate(), 'MMM d, yyyy');
    } catch (error) {
      return '';
    }
  };

  const formatTime = (date: any) => {
    try {
      if (!date || !date.toDate) return '';
      return format(date.toDate(), 'HH:mm');
    } catch (error) {
      return '';
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-center py-12"><p className="text-red-500">{error}</p></div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
      <div className="mb-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          {t('myBids.title')}
        </h1>
        <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
          {t('myBids.subtitle')}
        </p>
      </div>

      <div className="space-y-3">
        {bids?.map((bid) => (
          <div 
            key={bid.id}
            className="bg-white dark:bg-dark-800 rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow"
          >
            <div className="p-4">
              {/* Top Row: Sport Type, Court Name, Leading Status */}
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-2">
                  <span className="inline-block px-2 py-0.5 text-xs font-medium bg-indigo-100 dark:bg-indigo-900/50 text-indigo-800 dark:text-indigo-300 rounded">
                    {bid.auction?.court?.type || 'Tennis'}
                  </span>
                  <Link 
                    to={`/auction/${bid.auctionId}`}
                    className="text-base font-medium text-gray-900 dark:text-white hover:text-indigo-600 dark:hover:text-indigo-400"
                  >
                    {bid.auction?.court?.name}
                  </Link>
                </div>
                {bid.isLeading && (
                  <span className="flex items-center text-green-500 text-xs">
                    <Trophy className="h-3.5 w-3.5 mr-1" />
                    {t('myBids.leading')}
                  </span>
                )}
              </div>

              {/* Venue Name */}
              <Link 
                to={`/center/${bid.auction?.court?.venue?.id}`}
                className="block text-sm text-gray-500 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400 mb-3"
              >
                {bid.auction?.court?.venue?.name}
              </Link>

              {/* Main Content Row */}
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                {/* Left Side: Bids and Time Info */}
                <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-6 mb-3 sm:mb-0">
                  {/* Bid Amounts */}
                  <div className="flex items-center space-x-4 mb-2 sm:mb-0">
                    <div>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {t('myBids.yourBid')}
                      </p>
                      <p className="text-base font-semibold text-gray-900 dark:text-white">
                        €{bid.amount}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        {t('myBids.currentHighest')}
                      </p>
                      <p className="text-base font-semibold text-indigo-600 dark:text-indigo-400">
                        €{bid.currentHighest || bid.amount}
                      </p>
                    </div>
                  </div>

                  {/* Time Info */}
                  <div className="flex items-center space-x-3 text-xs text-gray-500 dark:text-gray-400">
                    <span className="flex items-center">
                      <Calendar className="h-3.5 w-3.5 mr-1" />
                      {formatDate(bid.auction?.date)}
                    </span>
                    <span className="flex items-center">
                      <Clock className="h-3.5 w-3.5 mr-1" />
                      {formatTime(bid.auction?.startTime)} - {formatTime(bid.auction?.endTime)}
                    </span>
                  </div>
                </div>

                {/* Right Side: Countdown and Button */}
                <div className="flex items-center justify-between sm:justify-end sm:space-x-4">
                  {bid.auction?.endTime && (
                    <div className="flex items-center text-xs text-orange-500">
                      <Clock className="h-3.5 w-3.5 mr-1" />
                      <Countdown endTime={bid.auction.endTime.toDate()} />
                    </div>
                  )}
                  <button
                    onClick={() => handleIncreaseBid(bid)}
                    className="px-4 py-1.5 text-sm bg-indigo-600 text-white rounded hover:bg-indigo-700 transition-colors whitespace-nowrap ml-4"
                  >
                    {t('myBids.increaseBid')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}

        {(!bids || bids.length === 0) && (
          <div className="text-center py-8">
            <p className="text-gray-500 dark:text-gray-400">
              {t('myBids.noBids')}
            </p>
          </div>
        )}
      </div>

      {selectedBid && (
        <BidModal
          auction={{
            id: selectedBid.auctionId,
            courtName: selectedBid.auction?.court?.name,
            venueName: selectedBid.auction?.court?.venue?.name,
            currentBid: selectedBid.currentHighest || selectedBid.amount,
            image: selectedBid.auction?.court?.venue?.profileImage
          }}
          onClose={() => setSelectedBid(null)}
        />
      )}
    </div>
  );
};