import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useLanguage } from '../../context/LanguageContext';

interface BidModalProps {
  auction: {
    id: string;
    courtName: string;
    venueName: string;
    currentBid: number;
    image?: string;
  };
  onClose: () => void;
}

export const BidModal: React.FC<BidModalProps> = ({ auction, onClose }) => {
  const { t } = useLanguage();
  const minimumBid = auction.currentBid + 5;
  const [bidAmount, setBidAmount] = useState(minimumBid.toString());

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add bid submission logic here
    onClose();
  };

  const handleBidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      // Ensure bid is at least minimum and rounded to nearest 5
      const roundedValue = Math.max(minimumBid, Math.ceil(value / 5) * 5);
      setBidAmount(roundedValue.toString());
    } else {
      setBidAmount(minimumBid.toString());
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-2 sm:p-4 z-50">
      <div className="bg-white dark:bg-dark-800 rounded-lg shadow-xl w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-4 sm:p-6 border-b border-gray-200 dark:border-dark-700">
          <h2 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
            {t('bid.placeBid')}
          </h2>
          <button 
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5 sm:h-6 sm:w-6" />
          </button>
        </div>

        <div className="p-4 sm:p-6">
          <div className="mb-4">
            <h3 className="font-medium text-gray-900 dark:text-white">
              {auction.courtName}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {auction.venueName}
            </p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {t('bid.currentBid')}
            </p>
            <p className="text-2xl font-bold text-indigo-600 dark:text-indigo-400">
              €{auction.currentBid}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              {t('bid.minimumBid')}: €{minimumBid}
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="bid-amount" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                {t('bid.amount')}
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 dark:text-gray-400 sm:text-sm">€</span>
                </div>
                <input
                  type="number"
                  name="bid-amount"
                  id="bid-amount"
                  min={minimumBid}
                  step="5"
                  required
                  value={bidAmount}
                  onChange={handleBidChange}
                  className="block w-full pl-7 pr-12 py-2 border border-gray-300 dark:border-dark-600 rounded-md focus:ring-indigo-500 focus:border-indigo-500 text-gray-900 dark:text-white bg-white dark:bg-dark-900"
                  placeholder={`${minimumBid}`}
                />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row sm:justify-end space-y-2 sm:space-y-0 sm:space-x-3">
              <button
                type="button"
                onClick={onClose}
                className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-dark-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t('common.cancel')}
              </button>
              <button
                type="submit"
                className="w-full sm:w-auto px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
              >
                {t('bid.confirm')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};