// src/hooks/useAdminStats.ts
import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit as firestoreLimit, Timestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface AdminStats {
  totalUsers: number;
  activeVenues: number;
  liveAuctions: number;
  totalRevenue: number;
  userGrowth: number;
  venueGrowth: number;
  auctionGrowth: number;
  revenueGrowth: number;
  recentActivity: Array<{
    id: string;
    type: string;
    message: string;
    timestamp: Date;
  }>;
}

export const useAdminStats = () => {
  const [stats, setStats] = useState<AdminStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const now = Timestamp.now();
        const thirtyDaysAgo = new Date(now.toMillis() - 30 * 24 * 60 * 60 * 1000);
        const sixtyDaysAgo = new Date(now.toMillis() - 60 * 24 * 60 * 60 * 1000);

        // Fetch current period stats
        const [
          usersSnapshot,
          activeVenuesSnapshot,
          liveAuctionsSnapshot,
          revenueSnapshot,
          recentActivitySnapshot
        ] = await Promise.all([
          getDocs(collection(db, 'users')),
          getDocs(query(collection(db, 'venues'), where('status', '==', 'active'))),
          getDocs(query(collection(db, 'auctions'), where('status', '==', 'active'))),
          getDocs(collection(db, 'paymentDistributions')),
          getDocs(query(
            collection(db, 'activity'),
            orderBy('timestamp', 'desc'),
            firestoreLimit(10)
          ))
        ]);

        // Calculate total revenue
        const totalRevenue = revenueSnapshot.docs.reduce((sum, doc) => {
          const data = doc.data();
          return sum + (data.platformFee || 0);
        }, 0);

        // Fetch previous period stats for growth calculation
        const [
          previousUsersSnapshot,
          previousVenuesSnapshot,
          previousAuctionsSnapshot,
          previousRevenueSnapshot
        ] = await Promise.all([
          getDocs(query(
            collection(db, 'users'),
            where('createdAt', '>=', sixtyDaysAgo),
            where('createdAt', '<', thirtyDaysAgo)
          )),
          getDocs(query(
            collection(db, 'venues'),
            where('createdAt', '>=', sixtyDaysAgo),
            where('createdAt', '<', thirtyDaysAgo)
          )),
          getDocs(query(
            collection(db, 'auctions'),
            where('createdAt', '>=', sixtyDaysAgo),
            where('createdAt', '<', thirtyDaysAgo)
          )),
          getDocs(query(
            collection(db, 'paymentDistributions'),
            where('createdAt', '>=', sixtyDaysAgo),
            where('createdAt', '<', thirtyDaysAgo)
          ))
        ]);

        // Calculate growth percentages
        const calculateGrowth = (current: number, previous: number) => {
          if (previous === 0) return 100;
          return ((current - previous) / previous) * 100;
        };

        const currentUsers = usersSnapshot.size;
        const previousUsers = previousUsersSnapshot.size;
        const userGrowth = calculateGrowth(currentUsers, previousUsers);

        const currentVenues = activeVenuesSnapshot.size;
        const previousVenues = previousVenuesSnapshot.size;
        const venueGrowth = calculateGrowth(currentVenues, previousVenues);

        const currentAuctions = liveAuctionsSnapshot.size;
        const previousAuctions = previousAuctionsSnapshot.size;
        const auctionGrowth = calculateGrowth(currentAuctions, previousAuctions);

        const previousRevenue = previousRevenueSnapshot.docs.reduce((sum, doc) => {
          const data = doc.data();
          return sum + (data.platformFee || 0);
        }, 0);
        const revenueGrowth = calculateGrowth(totalRevenue, previousRevenue);

        // Format recent activity
        const recentActivity = recentActivitySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp.toDate()
        }));

        setStats({
          totalUsers: currentUsers,
          activeVenues: currentVenues,
          liveAuctions: currentAuctions,
          totalRevenue,
          userGrowth,
          venueGrowth,
          auctionGrowth,
          revenueGrowth,
          recentActivity
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching admin stats:', err);
        setError(err instanceof Error ? err.message : 'Failed to load admin stats');
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return { stats, loading, error };
};
